import { createGlobalStyle } from 'styled-components'

import chivoRegular from 'styles/fonts/Chivo-Regular.otf'

export const THEME = {
  colors: {
    trueWhite: '#ffffff',
    veryLightGray: '#b7b7b7',
    lightGray: '#808080',
    darkGray: '#505050',
    mostlyBlack: '#333333',
  },
  // these are just aliases
  fonts: {
    chivoRegular: 'chivoRegular',
  },
  screenSize: {
    // notSmall is any screen >= 480px (30em)
    notSmall: `@media screen and (min-width: 30em)`,
    // medium is any screen >= 480px (30em) and <= 959px (60em) 
    medium: `@media screen and (min-width: 30em) and (max-width: 60em)`,
    // large is any screen >= 960px (60em) and <= 1920px (120em)
    large: `@media screen and (min-width: 60em) and (max-width: 120em)`,
    // gigantic is any screen >= 1920px (120em)
    gigantic: `@media screen and (min-width: 120em)`
  }
}

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    font-kerning: auto;
  }
	body {
    background-color: ${THEME.colors.lightGray};
    margin: 0;
    min-height: 100vh;
  }
	@font-face {
    font-family: chivoRegular;
    src: url(${chivoRegular}) format('opentype');
	}
`