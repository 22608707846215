import React from 'react'
import styled from 'styled-components'

import { IIcon } from 'util/types'

const X = ({size, strokeWidth, color}: IIcon) => (
  <SVG 
    xmlns='http://www.w3.org/2000/svg'
    width={size || '16'}
    height={size || '16'}
    viewBox='0 0 24 24'
    fill='none'
    stroke={color || 'currentColor'}
    strokeWidth={strokeWidth || '3'}
    strokeLinecap='round'
    strokeLinejoin='round'
    className='feather feather-x'
  >
    <line x1="18" y1="6" x2="6" y2="18"></line>
    <line x1="6" y1="6" x2="18" y2="18"></line>
  </SVG>
)

const SVG = styled.svg`
  vertical-align: middle;
`

export default X