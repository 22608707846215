import React, { useState, Dispatch } from 'react'
import styled from 'styled-components'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import ExpandingButton from 'components/boxContainers/ExpandingButton'
import Text from 'components/boxContents/Text'
import Link from 'components/boxContents/Link'
import { MENU_BUTTONS } from 'util/types'
import { linkTo } from 'util/helpers'

interface IProps {
  setPhotoMode: Dispatch<boolean>
}

const Menu = ({setPhotoMode, location}: IProps & RouteComponentProps) => {
  const [expanded, setExpanded] = useState<MENU_BUTTONS|null>(null)

  const showPhotoButton = false // location.pathname === '/photo'

  const expand = (cardToExpand: MENU_BUTTONS) => {
    setExpanded(expanded === cardToExpand ? null : cardToExpand)
  }

  return (
    <ContentWrapper>
      <ExpandingButton 
        button={MENU_BUTTONS.ABOUT} 
        isExpanded={expanded === MENU_BUTTONS.ABOUT}
        expand={expand}
        expandedContent={[
          <Text key='0' text="I'm Andy."/>,
          <Text key='1' text="I'm a software developer in Seattle." customMarginBlockEnd={'0rem'}/>
        ]}
      />
      <ExpandingButton 
        button={MENU_BUTTONS.RESUME} 
        isExpanded={expanded === MENU_BUTTONS.RESUME}
        expand={expand}
        expandedContent={[
          <Text key='0' text='I write a lot of Javascript.'/>,
          <Link key='1' text='github' onClickLink={() => linkTo('https://github.com/adierker')}/>,
          <Link key='2' text='linkedin' onClickLink={() => linkTo('https://linkedin.com/in/dierker/')} customMarginBlockEnd={'0rem'}/>
        ]}
      />
      { showPhotoButton &&
        <ExpandingButton 
          button={MENU_BUTTONS.PHOTO} 
          isExpanded={expanded === MENU_BUTTONS.PHOTO}
          expand={expand}
          expandedContent={[
            <Link key='2' text='2019' onClickLink={() => setPhotoMode(true)} customMarginBlockEnd={'0rem'}/>
          ]}
        />
      }
      <ExpandingButton 
        button={MENU_BUTTONS.CONTACT} 
        isExpanded={expanded === MENU_BUTTONS.CONTACT} 
        expand={expand}
        expandedContent={[
          <Text key='0' text='dierker at gmail dot com'/>
        ]}
      />
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30rem;
`

export default withRouter(Menu)