// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react'
import { useSpring, useChain, animated } from 'react-spring'
import { useMeasure } from 'react-use'
import styled from 'styled-components'

import FloatingWhiteBox from 'components/boxContainers/FloatingWhiteBox'
import { MENU_BUTTONS } from 'util/types'

interface IProps {
  button: MENU_BUTTONS
  expand: (button: MENU_BUTTONS) => void
  expandedContent: React.ReactNode[]
  isExpanded: boolean
}

const ExpandingButton = (props: IProps) => {
  const {
    button, 
    isExpanded, 
    expand, 
    expandedContent
  } = props

  const expansionAnimationRef = useRef()
  const opacityAnimationRef = useRef()
  
  // on every render, we measure the height of <Content ref={expandingContentRef}/>
  const [expandingContentRef, { height }] = useMeasure()
  const [contentHeight, setContentHeight] = useState(0)

  const expansionAnimation = useSpring({
    config: { friction: 25 },
    height: isExpanded ? contentHeight : 0,
    ref: expansionAnimationRef
  })

  const opacityAnimation = useSpring({
    config: {
      mass: 5.3,
      tension: 280,
      friction: 60
    },
    opacity: isExpanded ? 1 : 0,
    ref: opacityAnimationRef
  })

  // chains the animations together - the box expands first, then the text fades in
  useChain([expansionAnimationRef, opacityAnimationRef])

  useEffect(() => {
    setContentHeight(height)
    window.addEventListener('resize', setContentHeight(height))
    return window.removeEventListener('resize', setContentHeight(height))
  }, [height])

  return (
    <FloatingWhiteBox 
      onBoxClick={() => expand(button)}
    >
      <Button>
        {button}
      </Button>
      <animated.div style={expansionAnimation}>
        <Content isExpanded={isExpanded} ref={expandingContentRef}>
          <animated.div style={opacityAnimation}> 
            {expandedContent}
          </animated.div>
        </Content>
      </animated.div>
    </FloatingWhiteBox>
  )
}

const Button = styled.p`
  margin-block-start: 0rem;
  margin-block-end: 0rem;
  line-height: 1;
  font-size: 2.25rem;
`

const Content = styled.div<Pick<IProps, 'isExpanded'>>`
  margin-block-start: 1.5rem;
  display: ${({isExpanded}) => isExpanded ? 'block' : 'none'};
  line-height: normal;
  font-size: 1.5rem;
`

export default ExpandingButton