import React from 'react'
import styled from 'styled-components'

import { THEME } from 'styles/theme'
import ChevronRight from 'icons/ChevronRight'

interface IProps {
  text: string
  onClickLink: () => void
  customMarginBlockEnd?: string
}

const Link = ({
  text,
  onClickLink,
  customMarginBlockEnd
}: IProps) => {

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation()
    onClickLink()
  }

  return (
    <P customMarginBlockEnd={customMarginBlockEnd}>
      <ChevronRight/> 
      <A onClick={handleLinkClick} target='_blank'>
        {text}
      </A>
    </P>
  )
}

const P = styled.p<Pick<IProps, 'customMarginBlockEnd'>>`
  vertical-align: middle;
  margin-block-start: 0rem;
  margin-block-end: ${({customMarginBlockEnd}) => customMarginBlockEnd ? customMarginBlockEnd : '1rem'};
`

const A = styled.a`
  color: ${THEME.colors.lightGray};
  cursor: pointer;
  text-decoration: none;
  transition: all .2s ease-in;
  margin-left: 0.5rem;
  &:hover {
    color: ${THEME.colors.veryLightGray};
  }
`

export default Link