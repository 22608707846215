export const shuffleArray = (array: any[]) => {
  return [...Array(array.length)]
    .map((...args) => Math.floor(Math.random() * (args[1] + 1)))
    .reduce( (a, rv, i) => ([a[i], a[rv]] = [a[rv], a[i]]) && a, array)
}

export const getRandom = (array: any[]) => {
  return shuffleArray(array)[0]
}

export const linkTo = (string: string) => {
  window.open(string, '_blank')
}