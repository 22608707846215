export interface IIcon {
  size?: number
  strokeWidth?: number
  color?: string
}

export enum MENU_BUTTONS {
  RESUME = 'RESUME',
  ABOUT = 'ABOUT',
  PHOTO = 'PHOTO',
  CONTACT = 'CONTACT'
}

export interface IPhoto {
  year: string
  title: string
  url: string
}