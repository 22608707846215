import React, { useState, Dispatch } from 'react'
import styled, { css } from 'styled-components'

import FloatingWhiteBox from 'components/boxContainers/FloatingWhiteBox'
import X from 'icons/X'
import ArrowLeft from 'icons/ArrowLeft'
import ArrowRight from 'icons/ArrowRight'
import { THEME } from 'styles/theme'
import { IPhoto } from 'util/types'
import { linkTo } from 'util/helpers'


interface IProps {
  setPhotoMode: Dispatch<boolean>
  photos: IPhoto[]
}

const Photo = ({setPhotoMode, photos}: IProps) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0)
  const maxPhotoIndex = photos.length - 1
  const currentPhotoIsTheLastPhoto = currentPhotoIndex === maxPhotoIndex
  const currentPhotoIsTheFirstPhoto = currentPhotoIndex === 0

  const nextPhoto = () => {
    currentPhotoIsTheLastPhoto ? setCurrentPhotoIndex(0) : setCurrentPhotoIndex(currentPhotoIndex+1)
  }

  const prevPhoto = () => {
    currentPhotoIsTheFirstPhoto ? setCurrentPhotoIndex(maxPhotoIndex) : setCurrentPhotoIndex(currentPhotoIndex-1)
  }

  const { url } = photos[currentPhotoIndex]

  return (
    <ContentWrapper>
      <Row>
        <LeftAndRightControls>
          <FloatingWhiteBox
            children={<ArrowLeft/>}
            onBoxClick={prevPhoto}
            wrapperCss={leftButtonCss}
          />
          <FloatingWhiteBox 
            children={<ArrowRight/>}
            onBoxClick={nextPhoto}
            wrapperCss={rightButtonCss}
          />
        </LeftAndRightControls>
        <FloatingWhiteBox 
          children={<X/>} 
          onBoxClick={() => setPhotoMode(false)}
          wrapperCss={xButtonCss}
        />
      </Row>
      <FloatingWhiteBox 
        wrapperCss={photoCss}
        onBoxClick={() => linkTo(url)}
      >
        <Img src={url}/>
      </FloatingWhiteBox>
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vh;
  max-height: 100vh;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LeftAndRightControls = styled.div`
  display: flex;
`

const photoCss = css`
  margin-bottom: 0rem;
  padding: 0.5rem;
  ${THEME.screenSize.medium} { padding: 1rem; }
  ${THEME.screenSize.large} { padding: 1rem; }
  ${THEME.screenSize.gigantic} { padding: 2rem; }
`

const leftButtonCss = css`
  padding: 0.5rem;
  ${THEME.screenSize.notSmall} { padding: 1rem; }
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  ${THEME.screenSize.notSmall} {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
`

const rightButtonCss = css`
  padding: 0.5rem;
  ${THEME.screenSize.notSmall} { padding: 1rem; }
  margin-bottom: 0.5rem;
  ${THEME.screenSize.notSmall} {
    margin-bottom: 1rem;
  }
`

const xButtonCss = css`
  padding: 0.5rem;
  width: auto;
  ${THEME.screenSize.notSmall} { padding: 1rem; }
  margin-bottom: 0.5rem;
  ${THEME.screenSize.notSmall} {
    margin-bottom: 1rem;
  }
`

const Img = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  user-select: none;
`


export default Photo