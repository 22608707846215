import React, { useState } from 'react'
import styled from 'styled-components'

import { GlobalStyle } from 'styles/theme'
import { PHOTOS } from 'util/constants'
import { shuffleArray } from 'util/helpers'
import Menu from 'screens/Menu'
import Photo from 'screens/Photo'
import { IPhoto } from 'util/types'


const App = () => {
  const [photoMode, setPhotoMode] = useState<boolean>(false)
  const [photos, setPhotos] = useState<IPhoto[]>([])

  if (!photos.length) { 
    setPhotos(shuffleArray(PHOTOS)) 
  }

  return (
    <AppStyled>
      <GlobalStyle/>
      {photoMode ? (
        <Photo setPhotoMode={setPhotoMode} photos={photos}/>
      ) : ( 
        <Menu setPhotoMode={setPhotoMode}/>
      )}
    </AppStyled>
  )
}

const AppStyled = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`

export default App