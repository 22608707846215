import { IPhoto } from 'util/types'

export const PHOTOS: IPhoto[] = [
  {
    year: '2019',
    title: 'ferry',
    url: 'https://lh3.googleusercontent.com/lKNSfdv4WEp5a0QOYTHRKBF_hB7XfhR393zDHZC7-iOG2O7ZgivXlWGsMrOO4XUTa_-v6P0qEr4xvLx8VFAAgdBI8IbLT5T3Sofgz3TkOEJuqgLvojV3ZCmR6D10HonBm6--bKxOzw=w2400'
  },
  {
    year: '2019',
    title: 'guemes',
    url: 'https://lh3.googleusercontent.com/J3yuLv50OgX9v05HgDcW7FOSv8eEJ0LsPRQG4lX-9JLUfR5zSF236O_FeCVcIeLSlGCcucMsFxWT6RLlslRDFqVNZ1EKEvRlLPiPtRuqx_dh4ccKofAim-WDW0RXfv0_FDgRAbFPFg=w2400'
  },
  // {
  //   year: '2019',
  //   title: 'pool party',
  //   url: 'https://lh3.googleusercontent.com/Yw6XZI4UXa8dg9PUfm4ry2RvNctVXS8xRlPcV1pWUf1Y75WS-K7F6b-oO6Myr46kuui2SbDuhdXd6fsMIahL2jJxvPc3M65j6TrCUT7gXQ65gUYlVS63JuXuoN4Vg2dXtyiS1DrP_w=w2400'
  // },
  {
    year: '2019',
    title: 'free hugs',
    url: 'https://lh3.googleusercontent.com/8RKNXo6NEHisLCEFbI5_b6qhji-hZOLt9FDAWVBmTxLZp_xrQumlGzh_FlRFxkPp-YjFF46diP-lG12BYCZcFVozRfi8LI5sHm1xuHsDdZ7hZY5KFbh5weNt6n7k4DbtF_2Cwtj-TA=w2400'
  },
  {
    year: '2019',
    title: 'fireworks',
    url: 'https://lh3.googleusercontent.com/vAIswqo49WKQHD0YvcGB262rlsHYG7l5ivedsqTHGtr5Xnr-PaLqLXPATeI2TQOFx0yCE7ofY8q7DXCLlHYfzmdf0R4w07ezCug7yqLdnsKpNDJ2BeBEF6k8HrKqfVTYHpLBeeCaWg=w2400'
  },
  {
    year: '2019',
    title: 'alpine lake',
    url: 'https://lh3.googleusercontent.com/3uq8KqkHAoE6a5DIPvhWp3KbjTsCABXmNTrHc5kKMCR1_RZe-HDNaHZlltux0b7reO96wOmk-226eUm4AKeQNmdo0pDeYMRU_zUA-dtettq8a3wi71UmOy7i61Sdnmbt9UxObn3T2g=w2400'
  },
  {
    year: '2019',
    title: 'rent-a-fence',
    url: 'https://lh3.googleusercontent.com/LXNp71qAyHnce1FrOOYwbcXg9yDSi59gBotU51Pm_-AJb3T0dYmP-LDvsfmKm8SeePHXfD0jbxMMtjLgCDYQnrNVJXn-SuNKlzVUJiQ7MCevRQ_ab3eGx5-AcOoeu9BmDHA_3MA2vw=w2400'
  },
  {
    year: '2019',
    title: 'window',
    url: 'https://lh3.googleusercontent.com/UggYcDD2uqEVoxrNlWK4a5IjJoFDwHvWIIjsEPI9pxyD55Qbmw6WQ3KR39Lzv6P6nD9BwGHW7oV8Z085oCoQxv92SgLcFQBmLtEJFcRkRoM6u0hRJ-kHVHMhV-6ZTyFOqZ_vcd9KPg=w2400'
  },
  // {
  //   year: '2019',
  //   title: 'bush',
  //   url: 'https://lh3.googleusercontent.com/vbKgFz77MOHUjy7uj4BGA-gQ25a5CIz_t6jqgSGptZZX2IwayiWCB0MPvkjPI3Walu4grwIiEm5unL3a7-Z4kD3MMdSaZiIijNk1ovlZeYtHdU9KA2tlGCtDau6KnKpoyR_pcuRzkA=w2400'
  // },
  {
    year: '2019',
    title: 'fall',
    url: 'https://lh3.googleusercontent.com/7dCQRmBJLRzVM1zWvXAjIB7m7AQuBTr5DE3OdLcLNB8G5a9flh-7PZf4bDoq-4HYPQEz9xnVLv-_Vsn561EVGnO8L9dGoyQfw4pa30tpdFijjsk5OX-LYoi32uRfSbmOLeg_ZtUXlA=w2400'
  },
]