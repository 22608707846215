import React from 'react'
import styled from 'styled-components'

interface IProps {
  text: string
  customMarginBlockEnd?: string
}

const Text = ({text, customMarginBlockEnd}: IProps) => {
  return (
    <P customMarginBlockEnd={customMarginBlockEnd}>
      {text}
    </P>
  )
}

const P = styled.p<Pick<IProps, 'customMarginBlockEnd'>>`
  margin-block-start: 0rem;
  margin-block-end: ${({customMarginBlockEnd}) => customMarginBlockEnd ? customMarginBlockEnd : '1rem'};
`

export default Text