import React from 'react'
import styled from 'styled-components'

import { THEME } from 'styles/theme'

interface IProps {
  onBoxClick: () => void
  children?: React.ReactNode
  hoverable?: boolean
  hoverColor?: string
  wrapperCss?: any
}

const FloatingWhiteBox = ({
  children, 
  onBoxClick, 
  hoverable = true,
  wrapperCss,
}: IProps) => {

  return (
  <WhiteBox 
    onClick={onBoxClick || null} 
    hoverable={hoverable}
    wrapperCss={wrapperCss}
  >
    {children}
  </WhiteBox>
)}

const BORDER_SIZE = '3px'

const WhiteBox = styled.div<Pick<IProps, 'hoverable' | 'wrapperCss'>>`
  cursor: ${({hoverable}) => hoverable ? 'pointer' : 'auto'};
  color: ${THEME.colors.mostlyBlack};
  background-color: ${THEME.colors.trueWhite};
  border: ${BORDER_SIZE} solid ${THEME.colors.mostlyBlack};
  border-radius: 4px;
  font-family: ${THEME.fonts.chivoRegular};
  padding: 2rem;
  align-items: center;
  transition: all .2s ease-in;
  transition-property: top, left, background-color;
  width: 100%;
  position: relative;
  margin-bottom: 1rem;

  &:after {
    content: '';
    position: absolute;
    top: -${BORDER_SIZE};
    left: -${BORDER_SIZE};
    width: 100%;
    height: 100%;
    z-index: -99;
    background-color: ${THEME.colors.veryLightGray};
    border: ${BORDER_SIZE} solid ${THEME.colors.mostlyBlack};
    border-radius: 4px;
    transform-origin: right;
    transition-timing-function: cubic-bezier(0.165, -0.235, 0.58, 1.18);
    transition-property: transform;
    transition-duration: 300ms;
    will-change: transform;
  }

  &:hover:after{
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate(-10px);
    transition-duration: 200ms;
  }

  &&& { 
    ${({wrapperCss}) => wrapperCss}
  }
`

export default FloatingWhiteBox